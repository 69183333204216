import { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';

interface CheckBoxProps {
  isActive?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  classNames?: string;
  size?: 's' | 'm' | 'l';
  activeBorder?: 'grey' | 'primary';
}

export const CheckBox: FC<CheckBoxProps> = ({
  isActive,
  classNames,
  onClick,
  size = 's',
  activeBorder = 'grey',
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        {
          'bg-primary-100': isActive,
          'bg-white': !isActive,
          'h-4 w-4': size === 's',
          'h-6 w-6': size === 'm',
          'h-8 w-8': size === 'l',
          'border-grey-400': !isActive || activeBorder === 'grey',
          'border-primary-100': isActive && activeBorder === 'primary',
        },
        'flex items-center justify-center rounded-[5px] border',
        'group-hover:border-primary-100 flex-shrink-0 cursor-pointer transition-all',
        classNames,
      )}
    >
      <CheckIcon
        className={cn(
          {
            'h-2.5 w-2.5': size === 's',
            'h-3.5 w-3.5': size === 'm',
            'h-5 w-5': size === 'l',
          },
          'stroke-white',
        )}
      />
    </div>
  );
};
