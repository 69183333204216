import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { ProductType } from 'src/shared/api/catalog';
import { addSpaces } from 'src/shared/lib/utils';
import { DiscountPercent, DiscountPrice, Img, Stars } from 'src/shared/ui';

interface ProductListCardProps {
  data: ProductType;
  onFavorite: (data: ProductType) => void;
}

export const ProductListCard: FC<ProductListCardProps> = ({ data, onFavorite }) => {
  const { name, id, carouselImages, image, newPrice, discount, oldPrice, commentsCount, rating } =
    data;

  return (
    <div className="flex gap-6 rounded-2xl p-2 hover:shadow-100">
      <Link
        to={`/product/${id}`}
        className="relative aspect-square h-64 flex-shrink-0 overflow-hidden rounded-lg"
      >
        <FavoriteButton onClick={onFavorite} data={data} classNames="absolute top-4 right-4 z-10" />

        <Img src={image || carouselImages[0]} />
      </Link>

      <div className="flex flex-1 flex-col gap-2">
        <Link className="font-default-medium break-words break-all text-sm" to={`/product/${id}`}>
          {name}
        </Link>

        <div className="flex items-center">
          <Stars rate={rating} />
          <div className="font-default-medium ml-2 mt-1 text-xs">{commentsCount}</div>
        </div>
      </div>

      <div className="w-64 flex-shrink-0">
        <div>
          {discount && discount.toString() !== '0' && <DiscountPercent percent={discount} />}

          <div className="my-1 mr-8 flex items-center">
            <div className="font-default-semibold mr-2 whitespace-nowrap text-xl text-black-100">{`${addSpaces(+newPrice.replace(',', '.'))} ₽`}</div>
            {/*<SberSpasibo price={230} classNames="mb-1" />*/}
          </div>

          {discount && discount.toString() !== '0' && <DiscountPrice price={oldPrice} />}
        </div>

        <ProductAddButton product={data} className="mt-4" />
      </div>
    </div>
  );
};
