import { ButtonHTMLAttributes, FC } from 'react';
import cn from 'classnames';
import { ToolTip } from 'src/shared/ui';

import {
  ButtonSizesObjType,
  ButtonSizesType,
  ButtonVariantsObjType,
  ButtonVariantsType,
} from './types';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  tooltip?: string;
  variant?: ButtonVariantsType;
  size?: ButtonSizesType;
  classNames?: string;
}

export const Button: FC<ButtonProps> = ({
  tooltip,
  variant = 'white',
  size = 'base',
  classNames,
  children,
  disabled,
  ...props
}) => {
  const variants: ButtonVariantsObjType = {
    white: 'text-black-100 bg-white shadow-100',
    transparent: '',
    grey: 'text-primary-100 bg-grey-300 hover:bg-grey-400',
    greyBlack: 'text-black-100 bg-grey-300 hover:bg-grey-400',
    disabled: 'text-grey-500 bg-grey-300',
    greyOutline: cn(
      { 'hover:text-primary-100 hover:border-primary-100': !disabled },
      'border border-grey-400 text-grey-500',
    ),
    primary: 'text-white bg-primary-200 hover:bg-primary-300',
    primaryText: 'text-primary-100 hover:bg-grey-300',
  };

  const sizes: ButtonSizesObjType = {
    sm: 'font-default-medium text-sm rounded-full px-4 h-8',
    base: 'font-default-medium text-sm rounded-full px-4 py-2',
    lg: 'font-default-medium rounded-full text-lg h-12 px-7 py-3',
  };

  const button = (
    <button
      className={cn('flex items-center justify-center', variants[variant], sizes[size], classNames)}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );

  return tooltip ? <ToolTip label={tooltip}>{button}</ToolTip> : button;
};
