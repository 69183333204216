import { FC, ReactNode, useContext, useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { routesEnum } from 'src/shared/const';
import { UserContext } from 'src/shared/store/user';
import { Footer } from 'src/widgets/Footer';
import { Header } from 'src/widgets/Header';

import 'react-notifications-component/dist/theme.css';

interface MainContainerProps {
  children?: ReactNode;
}

export const MainContainer: FC<MainContainerProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, setUser, setIsUserLoading, isUserLoading } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const tokens = TokenService.getTokens();

      if (!tokens) {
        return setIsUserLoading(false);
      }

      try {
        const { data } = await AuthService.getUser();

        setUser(data);
      } catch (e) {
      } finally {
        setIsUserLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    const tokens = TokenService.getTokens();

    if (!user || !tokens) {
      return navigate(routesEnum.LOGIN);
    }

    const isPublicRoute = routesEnum.LOGIN === pathname;

    if (isPublicRoute) {
      navigate(routesEnum.CATALOG);
    }
  }, [user, isUserLoading, pathname]);

  return (
    <>
      <div className="flex h-dvh w-full flex-col d-xs:pt-16">
        <Header />
        <main className="flex flex-1 flex-col">{children}</main>
        {user && <Footer />}
        <ReactNotifications />
      </div>
    </>
  );
};
