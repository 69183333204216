import { FC } from 'react';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import cn from 'classnames';

interface AppSelectProps extends StateManagerProps {}

export const AppSelect: FC<AppSelectProps> = ({ ...props }) => {
  return (
    <Select
      isSearchable={false}
      {...props}
      classNames={{
        control: state => cn('font-default-regular', state.menuIsOpen && 'shadow-100'),
        option: state =>
          cn(
            'font-default-regular',
            state.isFocused ? 'bg-grey-700' : 'bg-white',
            state.isSelected ? 'text-primary-400' : 'text-black',
          ),
      }}
      styles={{
        container: provided => ({
          position: 'relative',
          width: '100%',
        }),
        control: () => ({
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          minHeight: '38',
          outline: '0',
          position: 'relative',
          borderRadius: '8px',
          borderColor: '#bec9d1',
          borderStyle: 'solid',
          borderWidth: '1px',
          height: '36px',
          fontSize: '14px',
          lineHeight: '20px',
          cursor: 'pointer',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menuList: () => ({
          maxHeight: 300,
          overflowY: 'auto',
          position: 'relative',
          padding: 0,
          boxShadow: '0 2px 8px 0 #08090a14',
          borderRadius: '8px',
        }),
        option: () => ({
          display: 'block',
          width: '100%',
          userSelect: 'none',
          padding: '8px 14px',
          fontSize: '14px',
          lineHeight: '20px',
          cursor: 'pointer',
        }),
      }}
    />
  );
};
