import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { FilterCheckBoxList } from 'src/entity/FilterCheckBoxList';
import { FilterTagList } from 'src/entity/FilterTagList';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { GroupType } from 'src/shared/api/catalog/types';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { marketLink, routesEnum } from 'src/shared/const';
import { CatalogContext, GroupContext, UserContext } from 'src/shared/store';
import { Interval } from 'src/shared/types';
import { Button, RangeSlider } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const GridFilter: FC = () => {
  const {
    filters,
    setFilters,
    setIsLoadingInitialFilters,
    setInitialFilters,
    initialFilters,
    isLoadingInitialFilters,
    resetFilters,
  } = useContext(CatalogContext);
  const { groups } = useContext(GroupContext);
  const { user } = useContext(UserContext);

  const { pathname } = useLocation();

  const [temp, setTemp] = useState({ min: 0, max: 0 });

  useEffect(() => {
    setTemp({
      min: +(initialFilters?.priceMin || 0),
      max: +(initialFilters?.priceMax || 0),
    });
  }, [initialFilters]);

  const handleResetFilters = () => {
    resetFilters();
    setTemp({
      min: +(initialFilters?.priceMin || 0),
      max: +(initialFilters?.priceMax || 0),
    });
  };

  const handleChangeFilter = (title: string, filterItem: string) => {
    setFilters(prev => {
      if (!prev.parameters?.[title]?.length) {
        return {
          ...prev,
          offset: 0,
          isAllLoading: false,
          parameters: { ...prev.parameters, [title]: [filterItem] },
        };
      }

      if (prev.parameters[title].includes(filterItem)) {
        return {
          ...prev,
          offset: 0,
          isAllLoading: false,
          parameters: {
            ...prev.parameters,
            [title]: prev.parameters[title].filter((el: string) => el !== filterItem),
          },
        };
      }

      return {
        ...prev,
        offset: 0,
        isAllLoading: false,
        parameters: { ...prev.parameters, [title]: [...prev.parameters[title], filterItem] },
      };
    });
  };

  const isFilterActive = useMemo(() => {
    if (!initialFilters) {
      return false;
    }

    let res = false;
    Object.keys(filters.parameters).forEach(key => {
      if (filters.parameters?.[key]?.length) {
        res = true;
      }
    });

    return (
      res ||
      filters.price.min !== +(initialFilters?.priceMin || 0) ||
      filters.price.max !== +(initialFilters?.priceMax || 0)
    );
  }, [filters, initialFilters]);

  const handleChangePrice = (price: Interval) => {
    setFilters(prev => ({ ...prev, offset: 0, isAllLoading: false, price }));
    setTemp(price);
  };

  const handleChangeGroup = async (group: GroupType) => {
    if (!user) {
      return;
    }

    try {
      setIsLoadingInitialFilters(true);

      const isBought = window.location.search.includes('bought');
      const isFavorite = pathname.includes(routesEnum.FAVORITES);

      const page = isBought ? 'bought' : isFavorite ? 'favorite' : 'catalog';

      const filtersRes = await CatalogService.getFilters(user.id, group.id, page);

      const min = filtersRes.data.priceMin ? +(filtersRes.data.priceMin.replace(',', '.') || 0) : 0;
      const max = filtersRes.data.priceMax ? +(filtersRes.data.priceMax.replace(',', '.') || 0) : 0;

      setInitialFilters({
        ...filtersRes.data,
        priceMax: max,
        priceMin: min,
      });

      setFilters(prev => ({
        ...prev,
        offset: 0,
        isAllLoading: false,
        parameters: {},
        selectedGroup: group,
        price: {
          min,
          max,
        },
      }));
    } catch (e) {
    } finally {
      setIsLoadingInitialFilters(false);
    }
  };

  const min = +(initialFilters?.priceMin || 0);
  const max = +(initialFilters?.priceMax || 0);

  return (
    <div className="flex w-full flex-col gap-10">
      <div>
        <div className="font-default-medium mb-5 text-black-100">Категория</div>
        <Link to={marketLink} className="flex items-center py-2">
          <ArrowSmallIcon className="mr-3 h-3 w-3 stroke-black-100" />
          <div className="font-default-regular text-sm text-black-100">
            Цифровые товары и услуги
          </div>
        </Link>

        {groups.map(group => (
          <div
            key={group.id}
            className={cn(
              {
                'bg-grey-700': filters.selectedGroup?.id === group.id,
                'cursor-pointer hover:bg-grey-700': filters.selectedGroup?.id !== group.id,
              },
              'group ml-2 rounded-lg py-2 pl-6 pr-3 transition-all',
            )}
            onClick={() => handleChangeGroup(group)}
          >
            <div
              className={cn(
                {
                  'text-primary-400': filters.selectedGroup?.id === group.id,
                  'group-hover:text-primary-400': filters.selectedGroup?.id !== group.id,
                },
                'font-default-regular text-sm transition-all',
              )}
            >
              {group.name}
            </div>
          </div>
        ))}
      </div>

      {isLoadingInitialFilters && <Loader classNames="w-20 h-20 mx-auto mt-20 text-primary-100" />}

      {!isLoadingInitialFilters && initialFilters ? (
        <>
          <div>
            <div className="font-default-medium text-sm">Цена</div>
            <div className="px-2">
              <RangeSlider
                min={min}
                max={max}
                value={filters.price}
                onChange={handleChangePrice}
                step={0.2}
              />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div
                className={
                  'font-default-medium flex h-9 w-full items-center gap-2 rounded-lg border ' +
                  'border-grey-400 px-2 text-sm'
                }
              >
                <div className="text-grey-600">от</div>
                <input
                  value={temp.min}
                  type="number"
                  className="w-20"
                  onBlur={e => {
                    const val = +e.target.value;
                    const temp = val >= min && val <= max ? val : min;
                    const tempRes = temp > filters.price.max ? filters.price.max : temp;
                    setFilters(prev => ({
                      ...prev,
                      offset: 0,
                      isAllLoading: false,
                      price: {
                        ...prev.price,
                        min: tempRes,
                      },
                    }));
                    setTemp(prev => ({ ...prev, min: tempRes }));
                  }}
                  onChange={e => {
                    const val = +e.target.value;
                    setTemp(prev => ({ ...prev, min: val }));
                  }}
                />
                <div className="ml-auto text-grey-600">₽</div>
              </div>
              <div
                className={
                  'font-default-medium flex h-9 w-full items-center gap-2 rounded-lg border ' +
                  'border-grey-400 px-2 text-sm'
                }
              >
                <div className="text-grey-600">до</div>
                <input
                  value={temp.max}
                  type="number"
                  className="w-20"
                  onBlur={e => {
                    const val = +e.target.value;
                    const temp = val >= min && val <= max ? val : max;
                    const tempRes = temp < filters.price.min ? filters.price.min : temp;

                    setFilters(prev => ({
                      ...prev,
                      offset: 0,
                      isAllLoading: false,
                      price: {
                        ...prev.price,
                        max: tempRes,
                      },
                    }));
                    setTemp(prev => ({ ...prev, max: tempRes }));
                  }}
                  onChange={e => {
                    const val = +e.target.value;
                    setTemp(prev => ({ ...prev, max: val }));
                  }}
                />
                <div className="ml-auto text-grey-600">₽</div>
              </div>
            </div>
          </div>

          {/*<FilterSwitchList*/}
          {/*  list={switchList}*/}
          {/*  activeFilters={activeFilters}*/}
          {/*  setFilter={handleChangeFilter}*/}
          {/*/>*/}

          {Object.keys(initialFilters.parameters).map(key => (
            <Fragment key={`filter-${key}`}>
              <FilterCheckBoxList
                title={key}
                list={initialFilters.parameters[key]}
                activeFilters={filters.parameters?.[key] || []}
                setFilter={handleChangeFilter}
                classNames="hidden d-xs:block"
              />
              <FilterTagList
                title={key}
                list={initialFilters.parameters[key]}
                activeFilters={filters.parameters?.[key] || []}
                setFilter={handleChangeFilter}
                classNames="d-xs:hidden"
              />
            </Fragment>
          ))}

          <Button
            variant={isFilterActive ? 'primary' : 'greyOutline'}
            classNames="hidden d-xs:flex mb-5"
            disabled={!isFilterActive}
            onClick={handleResetFilters}
          >
            Сбросить фильтры
          </Button>
        </>
      ) : null}
    </div>
  );
};
