import { FC } from 'react';
import { ReactComponent as StarIcon } from 'src/shared/assets/icons/kit/star.svg';

interface StarsProps {
  rate: number;
}

export const Stars: FC<StarsProps> = ({ rate }) => {
  const arr = Array.from({ length: 5 });

  return (
    <div className="relative flex w-fit">
      <div className="flex gap-1">
        {arr.map((_, i) => (
          <StarIcon key={`star-empty-${i}`} className="h-2.5 w-2.5 fill-grey-500" />
        ))}
      </div>
      <div
        className="absolute left-0 top-0 flex gap-1 overflow-hidden"
        style={{ width: `${rate}%` }}
      >
        {arr.map((_, i) => (
          <StarIcon key={`star-empty-${i}`} className="h-2.5 w-2.5 flex-shrink-0 fill-orange-100" />
        ))}
      </div>
    </div>
  );
};
