import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { UserContext } from 'src/app/providers/UserProvider';
import { Container } from 'src/entity/Container';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/kit/arrow.svg';
import { ReactComponent as ChatIcon } from 'src/shared/assets/icons/kit/chat.svg';
import { ReactComponent as StarIcon } from 'src/shared/assets/icons/kit/star.svg';
import { routesEnum } from 'src/shared/const';
import { addSpaces, declOfNum } from 'src/shared/lib/utils';
import { Button, DiscountPercent, DiscountPrice, Img, WhiteBox } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const ProductPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const [currentImage, setCurrentImage] = useState(0);
  const [product, setProduct] = useState<ProductType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [similarProducts, setSimilarProducts] = useState<ProductType[]>([]);

  const carousel = useMemo(() => {
    if (!product) {
      return [''];
    }
    const { image, carouselImages } = product;

    const res: string[] = [];

    if (image) {
      res.push(image);
    }

    if (carouselImages?.length) {
      res.push(...carouselImages);
    }

    return res?.length ? res : [''];
  }, [product]);

  useEffect(() => {
    if (!id || !user?.id) {
      return;
    }

    (async () => {
      try {
        setIsLoading(true);

        const { data } = await CatalogService.getProduct(id, user.id);

        setProduct(data[0]);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, user]);

  useEffect(() => {
    if (!product || !id || !user?.id) {
      return;
    }

    (async () => {
      try {
        const { data } = await CatalogService.getSimilarProducts(id, user.id);

        setSimilarProducts(data);
      } catch (e) {}
    })();
  }, [product]);

  const onFavorite = (product: ProductType) => {
    setSimilarProducts(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  const onFavoriteSingle = () => {
    setProduct(prev => (prev ? { ...prev, favorite: !prev.favorite } : prev));
  };

  if (!isLoading && !product) {
    return (
      <div className="mx-auto mt-40 flex w-40 flex-col items-center">
        <div className="font-default-medium mb-8">Товар не найден</div>
        <Button variant="primary" classNames="w-full" onClick={() => navigate(routesEnum.CATALOG)}>
          В каталог
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return <Loader classNames="w-20 h-20 mx-auto mt-20 text-primary-100" />;
  }

  const handleNext = () => {
    console.log('1111');
    setCurrentImage(prev => (prev < carousel.length - 1 ? prev + 1 : 0));
  };

  const handlePrev = () => {
    setCurrentImage(prev => (prev !== 0 ? prev - 1 : carousel.length - 1));
  };

  return (
    product && (
      <>
        <div className="relative flex w-full flex-col items-center d-xs:hidden">
          <button
            onClick={() => navigate(-1)}
            className={
              'absolute left-8 top-4 flex h-8 w-8 items-center justify-center rounded-full bg-white'
            }
          >
            <ArrowIcon className="h-5 w-5 fill-grey-500" />
          </button>

          <div
            className={
              'absolute right-6 top-6 flex h-8 w-8 items-center justify-center ' +
              'rounded-full bg-white'
            }
          >
            <FavoriteButton onClick={onFavoriteSingle} data={product} />
          </div>

          <div className="group relative h-96 w-full bg-grey-800">
            <div className="absolute left-0 top-0 z-40 h-full w-1/2" onClick={handlePrev} />
            <div className="absolute right-0 top-0 z-40 h-full w-1/2" onClick={handleNext} />
            <div className="mx-auto aspect-square h-full">
              <Img src={carousel[currentImage]} />
            </div>
          </div>
          <div className="flex gap-2 py-4">
            {carousel.map((_, i) => (
              <div
                onClick={() => setCurrentImage(i)}
                className={cn(
                  {
                    'bg-primary-100': i === currentImage,
                    'bg-grey-400': i !== currentImage,
                  },
                  'h-1.5 w-1.5 cursor-pointer rounded-full transition-all',
                )}
                key={`img-1-${i}`}
              />
            ))}
          </div>
        </div>

        <Container classNames="flex-1">
          <div className="mb-16 hidden h-32 w-full items-center rounded-3xl bg-grey-1000 px-4 d-xs:flex">
            <div className="h-16 w-16">
              <Img src={product.shop.photo} classNames="bg-grey-1000" noBg />
            </div>

            <div className="font-default-medium ml-8 text-3xl">{product.shop.name}</div>
          </div>

          <div className="font-default-medium mb-4 text-lg d-xs:mb-8 d-xs:text-3xl">
            {product.name}
            <FavoriteButton
              text
              onClick={onFavoriteSingle}
              data={product}
              classNames="ml-auto hidden d-xs:flex"
            />
          </div>

          <div className="font-default-medium mb-4 flex items-center gap-8 text-xs">
            <div className="flex items-center">
              <StarIcon className="-mt-1 mr-2 h-2.5 w-2.5 fill-orange-100" />
              {(product.rating / 20).toFixed(1)}
            </div>

            <div className="flex items-center text-grey-500">
              {`${product.commentsCount} ${declOfNum(product.commentsCount, ['отзыв', 'отзыва', 'отзывов'])}`}
            </div>
            <div className="flex items-center text-grey-500">
              <ChatIcon className="mr-2 h-3 w-3 fill-grey-500" />
              {`${3} ${declOfNum(3, ['вопрос', 'вопроса', 'вопросов'])}`}
            </div>
            <div className="flex items-center text-grey-500"></div>
          </div>

          <div className="flex flex-col d-xs:flex-row">
            <div className="hidden flex-shrink-0 flex-col gap-2 d-xs:flex">
              {carousel.map((img, i) => (
                <div
                  onClick={() => setCurrentImage(i)}
                  className={cn(
                    {
                      'border-primary-100': i === currentImage,
                      'hover:border-primary-100': i !== currentImage,
                    },
                    'h-12 w-12 cursor-pointer rounded-xl border p-1 transition-all',
                  )}
                  key={`img-${i}`}
                >
                  <Img src={img} classNames="rounded-lg" />
                </div>
              ))}
            </div>
            <div className="group mx-2 mr-8 hidden aspect-square h-[26rem] w-[26rem] flex-shrink-0 d-xs:block">
              <Img src={carousel[currentImage] || ''} />
            </div>

            <div className="mb-8 d-xs:mb-0 d-xs:mr-8 d-xs:flex-1">
              {product.parameters.map(parameter => (
                <div
                  key={parameter.parameterId}
                  className="font-default-medium mb-2 flex text-sm"
                >{`${parameter.parameterName}: ${parameter.parameterValues.map(el => el.valueName).join(', ')}`}</div>
              ))}
              <div className="font-default-regular text-sm">{product.description}</div>
            </div>

            <WhiteBox classNames="flex-shrink-0 d-xs:w-96 w-full h-fit">
              <div className="mb-5 flex items-center gap-3">
                <div className="font-default-medium text-4xl">{`${addSpaces(+product.newPrice.replace(',', '.'))} ₽`}</div>
                {product.discount && product.discount.toString() !== '0' && (
                  <DiscountPrice size="l" price={product.oldPrice} />
                )}
              </div>
              {/*<SberSpasiboBlock classNames="mb-5" />*/}

              <ProductAddButton product={product} className="mt-4" />
            </WhiteBox>
          </div>
        </Container>

        {similarProducts?.length ? (
          <Container classNames="w-full mt-5 d-xs:mt-8">
            <div className="font-default-medium mb-3 pl-2 text-xl d-xs:text-2xl">
              Похожие товары
            </div>
            <div className="flex w-full flex-wrap">
              {similarProducts.map(el => (
                <ProductGridCard onFavorite={onFavorite} key={el.id} data={el} />
              ))}
            </div>
          </Container>
        ) : null}
      </>
    )
  );
};
