import { ChangeEvent, FC, useContext, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/kit/arrow.svg';
import { ReactComponent as CatalogIcon } from 'src/shared/assets/icons/kit/catalog.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as HomeIcon } from 'src/shared/assets/icons/kit/home.svg';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { ReactComponent as ShoppingCartIcon } from 'src/shared/assets/icons/kit/shopping-cart.svg';
import { marketLink, routesEnum } from 'src/shared/const';
import { debounce } from 'src/shared/lib/utils';
import { BasketContext, CatalogContext } from 'src/shared/store';

import { useSearchHistory } from '../hooks';

export const Footer: FC = () => {
  const searchRef = useRef<HTMLInputElement>(null);

  const { pathname } = useLocation();
  const { filters, setFilters } = useContext(CatalogContext);
  const { basket } = useContext(BasketContext);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const { add, list } = useSearchHistory(search);

  const fullAmount = useMemo(() => {
    if (!basket.length) {
      return 0;
    }

    return basket.reduce((sum, curr) => {
      return sum + curr.amount;
    }, 0);
  }, [basket]);

  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  const linkStyles = cn(
    { 'px-6': !isSearchOpen },
    'group flex items-center h-full rounded-full relative',
  );

  const getActiveStyles = (path: string) => (pathname === path ? 'bg-white' : 'hover:bg-grey-200');
  const getIconStyles = (path: string) =>
    cn(
      {
        'fill-black-100': pathname === path,
        'fill-white': pathname !== path,
      },
      'w-5 h-5',
    );

  const links = [
    {
      path: marketLink,
      icon: <HomeIcon className={getIconStyles('/')} />,
    },
    {
      path: routesEnum.CATALOG,
      icon: <CatalogIcon className={getIconStyles(routesEnum.CATALOG)} />,
    },
    {
      path: routesEnum.FAVORITES,
      icon: <HeartIcon className={getIconStyles(routesEnum.FAVORITES)} />,
    },
  ];

  const left = {
    '/': 6,
    '/catalog': 74,
    '/favorites': 142,
  };

  const handleSearch = () => {
    const search = searchRef?.current?.value || '';
    setFilters(prev => ({ ...prev, offset: 0, search, isAllLoading: false }));
    search && add(search);
    setIsSearchOpen(false);
  };

  const handleResetSearch = () => {
    setFilters(prev => ({ ...prev, offset: 0, search: '', isAllLoading: false }));
    setIsSearchOpen(false);
    setSearch('');
  };

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 300);

  return (
    <footer>
      {isSearchOpen && (
        <div className="fixed inset-0 z-1000 flex items-center justify-center bg-grey-100 backdrop-blur-3xl">
          <div className="relative overflow-hidden rounded-2xl bg-white">
            <div className="flex h-12 items-center">
              <input
                className="font-default-medium text-black-104 mr-2 w-80 pl-4 pr-8"
                placeholder="Поиск"
                defaultValue={filters.search}
                onChange={handleChange}
                ref={searchRef}
              />
              {(filters.search || search) && (
                <div
                  className="absolute right-9 top-0 flex aspect-square h-12 cursor-pointer items-center justify-center"
                  onClick={handleResetSearch}
                >
                  <CloseIcon className="h-5 w-5 fill-grey-500 p-0.5" />
                </div>
              )}
              <div
                className="flex aspect-square h-full cursor-pointer items-center justify-center"
                onClick={handleSearch}
              >
                <LoupeIcon className="h-5 w-5 fill-grey-500" />
              </div>
            </div>

            {list?.length ? (
              <div className="max-h-80 overflow-auto">
                {list.map(el => (
                  <div
                    key={el}
                    className="cursor-pointer border-t border-grey-300 px-4 py-2 hover:bg-grey-300"
                    onClick={() => {
                      if (!searchRef?.current) {
                        return;
                      }
                      searchRef.current.value = el;
                      handleSearch();
                    }}
                  >
                    {el}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      )}

      <div className="fixed bottom-4 left-1/2 z-1000 hidden h-16 -translate-x-1/2 gap-4 d-xs:flex">
        <div
          className={cn(
            {
              'w-0 p-0 opacity-0':
                pathname !== '/basket' &&
                !pathname.includes('/product') &&
                !pathname.includes('/profile'),
              'opacity-1 w-20 p-1.5':
                pathname === '/basket' ||
                pathname.includes('/product') ||
                pathname.includes('/profile'),
            },
            'transition-all-slow h-full cursor-pointer rounded-full bg-grey-100 backdrop-blur-3xl',
          )}
          onClick={handleBack}
        >
          <div className={linkStyles}>
            <ArrowIcon className={getIconStyles('')} />
          </div>
        </div>

        <div
          className={cn(
            { 'bg-grey-100 backdrop-blur-3xl': !isSearchOpen },
            'relative flex h-full rounded-full p-1.5',
          )}
        >
          <div
            className={cn(
              {
                'w-[68px]': Boolean(left?.[pathname]),
                'w-0 p-0 opacity-0': !Boolean(left?.[pathname]) || isSearchOpen,
              },
              'transition-all-slow absolute top-1.5 h-[52px] rounded-full bg-white',
            )}
            style={{ left: `${left?.[pathname] || 6}px` }}
          />

          {links.map((link, i) => (
            <Link
              to={link.path}
              key={`footer-link-${i}`}
              className={cn(
                { 'w-0 p-0 opacity-0': isSearchOpen },
                linkStyles,
                getActiveStyles(link.path),
              )}
            >
              {link.icon}
            </Link>
          ))}

          <div
            className={cn(
              { 'w-0 p-0 opacity-0': isSearchOpen, 'bg-white hover:bg-white': !!filters.search },
              linkStyles,
              getActiveStyles(''),
              'cursor-pointer',
            )}
            onClick={() => setIsSearchOpen(true)}
          >
            <LoupeIcon
              className={cn(
                {
                  'fill-black-100': !!filters.search,
                  'fill-white': !filters.search,
                },
                'h-5 w-5',
              )}
            />

            {filters.search && (
              <div className="font-default-medium max-w-32 truncate pl-2 text-black-100">
                {filters.search}
              </div>
            )}
          </div>

          <div
            className={cn(
              { 'w-0 p-0 opacity-0': !isSearchOpen, 'px-6': isSearchOpen },
              'flex h-full cursor-pointer items-center rounded-full bg-white ',
            )}
            onClick={() => setIsSearchOpen(false)}
          >
            <CloseIcon className="h-5 w-5 fill-black-100" />
          </div>
        </div>

        <div
          className={cn(
            {
              'w-0 p-0 opacity-0': pathname === '/basket' || isSearchOpen,
              'opacity-1 w-20 p-1.5': pathname !== '/basket' && !isSearchOpen,
            },
            'transition-all-slow relative h-full rounded-full bg-grey-100 backdrop-blur-3xl',
          )}
        >
          {fullAmount ? (
            <div
              className={cn(
                'font-default-medium absolute -top-1 right-0 rounded-full px-2 text-sm',
                'bg-primary-100 border-2 border-white text-white',
              )}
            >
              {fullAmount}
            </div>
          ) : null}

          <Link to="/basket" className={linkStyles}>
            <ShoppingCartIcon className={getIconStyles('')} />
          </Link>
        </div>
      </div>
    </footer>
  );
};
