import { FC } from 'react';
import cn from 'classnames';

interface FilterTagListProps {
  title: string;
  activeFilters: string[];
  list: string[];
  setFilter: (title: string, filter: string) => void;
  classNames?: string;
}

export const FilterTagList: FC<FilterTagListProps> = ({
  title,
  activeFilters,
  list,
  setFilter,
  classNames,
}) => {
  return (
    <div className={cn('w-full', classNames)}>
      <div className="font-default-semibold mb-5 text-sm">{title}</div>

      <div className="flex flex-wrap gap-2">
        {list.map(item => (
          <div
            className={cn(
              {
                'bg-grey-300 text-black-100': !activeFilters.includes(item),
                'bg-black-100 text-white': activeFilters.includes(item),
              },
              'font-default-medium cursor-pointer rounded-lg px-3 py-1.5 text-sm',
            )}
            key={`${title}-${item}`}
            onClick={() => setFilter(title, item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
