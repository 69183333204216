import { FC, useContext, useState } from 'react';
import cn from 'classnames';
import { Container } from 'src/entity/Container';
import { BannersList } from 'src/feature/BannersList';
import { GridFilter } from 'src/feature/GridFilter';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import { ProductListCard } from 'src/feature/ProductListCard';
import { ProductType } from 'src/shared/api/catalog';
import { sort } from 'src/shared/const';
import { useWindowDimensions } from 'src/shared/lib/hooks';
import { BannersContext } from 'src/shared/store';
import { Loader } from 'src/shared/ui/Loader';
import { CatalogHead } from 'src/widgets/CatalogHead';

import { useFilters, useProducts } from './hooks';

interface CatalogPageProps {
  isFavorite?: boolean;
}

export const CatalogPage: FC<CatalogPageProps> = ({ isFavorite }) => {
  const { width } = useWindowDimensions();
  const { banners } = useContext(BannersContext);

  const [isGrid, setIsGrid] = useState(true);
  const [selectedSort, setSelectedSort] = useState(sort[0]);
  const [isBought, setIsBought] = useState<boolean>(window.location.search.includes('bought'));

  useFilters(isFavorite, isBought);
  const { isLoading, products, setProducts } = useProducts(selectedSort, isBought, isFavorite);

  const onFavorite = (product: ProductType) => {
    setProducts(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  return (
    <Container classNames="">
      {isFavorite ? (
        <div className="font-default-medium mb-4 flex">
          <button
            onClick={() => setIsBought(false)}
            className={cn(
              {
                'border-primary-100': !isBought,
                'hover:border-primary-100 border-grey-400 text-grey-500 hover:text-black': isBought,
              },
              'border-b px-2.5 pb-2 transition-all',
            )}
          >
            Сохранили
          </button>
          <button
            onClick={() => setIsBought(true)}
            className={cn(
              {
                'border-primary-100': isBought,
                'hover:border-primary-100 border-grey-400 text-grey-500 hover:text-black':
                  !isBought,
              },
              'border-b px-2.5 pb-2 transition-all',
            )}
          >
            Покупали
          </button>
          <div className="w-full border-b border-grey-400" />
        </div>
      ) : null}

      <div className="flex flex-1">
        <div className="hidden h-full w-72 flex-shrink-0 d-xs:block">
          <div className="sticky top-20">
            <div className="flex flex-col">
              <GridFilter />
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col d-xs:ml-5">
          {banners?.length && !isFavorite ? (
            <div className="mb-4 h-12 w-full overflow-hidden m-md:h-16 t-sm:h-24 d-xs:h-28">
              <BannersList list={banners} />
            </div>
          ) : null}

          <CatalogHead
            isGrid={isGrid}
            setIsGrid={setIsGrid}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
          />

          <div className="sticky flex-1">
            <div className={cn({ 'flex flex-wrap d-xs:gap-2.5': isGrid })}>
              {isLoading && <Loader classNames="mx-auto mt-40 w-20 h-20 text-primary-100" />}

              {!isLoading && !products.length ? (
                <div className="font-default-medium mx-auto mt-40">Товары не найдены</div>
              ) : null}

              {!isLoading && products.length
                ? products.map(el =>
                    !isGrid && width >= 1200 ? (
                      <ProductListCard onFavorite={onFavorite} key={el.id} data={el} />
                    ) : (
                      <ProductGridCard onFavorite={onFavorite} key={el.id} data={el} />
                    ),
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
